/**
 * CIE LUV (C'est la vie)
 *
 * @module color-space/luv
 */
import xyz from './xyz.js';

/** @type {Partial<import('./index.js').ColorSpace>} */
var luv = {
  name: 'luv',
  //NOTE: luv has no rigidly defined limits
  //easyrgb fails to get proper coords
  //boronine states no rigid limits
  //colorMine refers this ones:
  min: [0, -134, -140],
  max: [100, 224, 122],
  channel: ['lightness', 'u', 'v'],
  alias: ['LUV', 'cieluv', 'cie1976'],
  xyz: function (arg, i, o) {
    var _u, _v, l, u, v, x, y, z, xn, yn, zn, un, vn;
    l = arg[0], u = arg[1], v = arg[2];
    if (l === 0) return [0, 0, 0];

    //get constants
    //var e = 0.008856451679035631; //(6/29)^3
    var k = 0.0011070564598794539; //(3/29)^3

    //get illuminant/observer
    i = i || 'D65';
    o = o || 2;
    xn = xyz.whitepoint[o][i][0];
    yn = xyz.whitepoint[o][i][1];
    zn = xyz.whitepoint[o][i][2];
    un = 4 * xn / (xn + 15 * yn + 3 * zn);
    vn = 9 * yn / (xn + 15 * yn + 3 * zn);
    // un = 0.19783000664283;
    // vn = 0.46831999493879;

    _u = u / (13 * l) + un || 0;
    _v = v / (13 * l) + vn || 0;
    y = l > 8 ? yn * Math.pow((l + 16) / 116, 3) : yn * l * k;

    //wikipedia method
    x = y * 9 * _u / (4 * _v) || 0;
    z = y * (12 - 3 * _u - 20 * _v) / (4 * _v) || 0;

    //boronine method
    //https://github.com/boronine/husl/blob/master/husl.coffee#L201
    // x = 0 - (9 * y * _u) / ((_u - 4) * _v - _u * _v);
    // z = (9 * y - (15 * _v * y) - (_v * x)) / (3 * _v);

    return [x, y, z];
  }
};
export default (/** @type {import('./index.js').ColorSpace} */luv);

// http://www.brucelindbloom.com/index.html?Equations.html
// https://github.com/boronine/husl/blob/master/husl.coffee
//i - illuminant
//o - observer
xyz.luv = function (arg, i, o) {
  var _u, _v, l, u, v, x, y, z, xn, yn, zn, un, vn;

  //get constants
  var e = 0.008856451679035631; //(6/29)^3
  var k = 903.2962962962961; //(29/3)^3

  //get illuminant/observer coords
  i = i || 'D65';
  o = o || 2;
  xn = xyz.whitepoint[o][i][0];
  yn = xyz.whitepoint[o][i][1];
  zn = xyz.whitepoint[o][i][2];
  un = 4 * xn / (xn + 15 * yn + 3 * zn);
  vn = 9 * yn / (xn + 15 * yn + 3 * zn);
  x = arg[0], y = arg[1], z = arg[2];
  _u = 4 * x / (x + 15 * y + 3 * z) || 0;
  _v = 9 * y / (x + 15 * y + 3 * z) || 0;
  var yr = y / yn;
  l = yr <= e ? k * yr : 116 * Math.pow(yr, 1 / 3) - 16;
  u = 13 * l * (_u - un);
  v = 13 * l * (_v - vn);
  return [l, u, v];
};